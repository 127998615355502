import React, { useState, useEffect, useRef, useCallback } from 'react';
import Layout from '../layout/Layout';
import axios from '../common/axios';
import _, { multiply, set } from "lodash"
import Swal from 'sweetalert2';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import { CellRender, RowRender } from './renderers';
import {
	IntlProvider,
	LocalizationProvider,
	loadMessages, load
} from "@progress/kendo-react-intl";
import debounce from 'lodash/debounce';
import krMessage from './kr.json'
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { read, utils, writeFile } from 'xlsx';
import { useNavigate } from 'react-router-dom'; // 변경된 부분
import { Upload } from '@progress/kendo-react-upload';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import BigNumber from 'bignumber.js';

load(
	likelySubtags,
	currencyData,
	weekData,
	numbers,
	currencies,
	caGregorian,
	dateFields,
	timeZoneNames
);
loadMessages(krMessage, "ko-KR");

axios.defaults.timeout = 300000;

const initialDataState = {
	skip: 0,
	take: 10
};

const PaymentTable = ({ tabledata, originalPayment, paymentRecordNo }) => {
	const navigate = useNavigate(); // 변경된 부분
	const exportData = tabledata && tabledata.sov.map(v => {
		const matchingPayment = originalPayment && originalPayment.find(payment => payment._refnum === Number(v.li_num));

		// const materialCost = Math.floor(v.uuu_unit_price_m * v.ItemQuantity* (100+Number(v.spocMarginPerc))/100);
		// const laborCost = Math.floor(v.uuu_unit_price_l * v.ItemQuantity);
		// const expenseCost = Math.floor(v.uuu_unit_price_e * v.ItemQuantity);
		// const contractAmount = materialCost + laborCost + expenseCost;

		return {
			id: v.li_num,
			"계약 유형": v.uuu_cost_li_type === "Unit Cost" ? "단가" : v.uuu_cost_li_type === "Lump Sum" ? "총액" : "",
			"품명": v.spocItemName,
			"규격": v.spocSpec,
			"단위": v.spocUoMTxt,
			"계약 수량": parseFloat(v.ItemQuantity).toFixed(4),
			"계약 단가": Math.round(v.uuu_unit_price),
			"자재 단가": Math.round(v.uuu_unit_price_m),
			"노무 단가": Math.round(v.uuu_unit_price_l),
			"경비 단가": Math.round(v.uuu_unit_price_e),
			"계약 금액": v.amount,
			"금회 금액": v.upaCurrPaymentDueCA,
			"할증률": v.spocMarginPerc,
			"누적수량": v["누적 기성 물량"],
			"누적 기성지급액": v["누적 금액"] ? Math.round(v["누적 금액"]) : 0,
			"금회 수량/금액": matchingPayment ? (v.uuu_cost_li_type === "Unit Cost" ? matchingPayment.uuu_spa_qty_tp : matchingPayment.uuu_spa_amt_tp) : 0,
			"possbileAmount": v.possbileAmount,
			Description: v.Description,
			bItemID: v["bItemID"],
			LineAutoSeq: matchingPayment && matchingPayment.LineAutoSeq
		};
	});

	const EDIT_FIELD = 'inEdit';
	const [data, setData] = React.useState(exportData);
	const [Advpayment, setAdvpayment] = React.useState({});
	const [page, setPage] = React.useState(initialDataState);
	const [pageSizeValue, setPageSizeValue] = React.useState();
	const [isSubmitting, setIsSubmitting] = useState(false);


	const [showNoAdvancePaymentAlert, setShowNoAdvancePaymentAlert] = useState(false);


	const calculateValidity = (dataItem) => {
		const contractType = dataItem["계약 유형"];

		const surchargeRate = new BigNumber(dataItem["할증률"]);
		const contractQuantity = new BigNumber(dataItem["계약 수량"]);
		const thisAmount = new BigNumber(dataItem["금회 수량/금액"]);
		const possbileAmount = new BigNumber(dataItem.possbileAmount);
		const accumlatedAmount = new BigNumber(dataItem["누적 기성지급액"]);
		const preQuantity = new BigNumber(dataItem["누적수량"] || 0);  // 값이 없으면 0으로 설정 


		const materialCost = new BigNumber(dataItem["자재 단가"]);
		const laborCost = new BigNumber(dataItem["노무 단가"]);
		const expenseCost = new BigNumber(dataItem["경비 단가"]);
		const contractAmount = new BigNumber(dataItem["계약 금액"]);

		let payment;
		if (contractType === "총액") {
			payment =
				contractQuantity.times(thisAmount.integerValue(BigNumber.ROUND_FLOOR))

		} else {
			const contractUnitPrice = new BigNumber(dataItem["계약 단가"]);
			payment =
				materialCost.times(thisAmount.times(surchargeRate.div(100).plus(1)).toFixed(4)).integerValue(BigNumber.ROUND_FLOOR)
					.plus(laborCost.times(thisAmount)).integerValue(BigNumber.ROUND_FLOOR)
					.plus(expenseCost.times(thisAmount)).integerValue(BigNumber.ROUND_FLOOR);
		}


		const finalValue = contractAmount.minus(payment).minus(accumlatedAmount);
		const finalValueNegative = finalValue.isNegative();

		const finalQuantity = contractQuantity.minus(thisAmount).minus(preQuantity);
		const finalQuantityNegativeOrZero = finalQuantity.isNegative() || finalQuantity.isZero();


		if (dataItem["계약 유형"] === "총액" && (finalValue.isZero() || (finalValue.toNumber() > 0 && finalValue.toNumber() < 1))) {

			payment = possbileAmount

		}

		if (dataItem["계약 유형"] === "단가" && (finalQuantity.isZero())) {
			payment = possbileAmount

		}

		payment = payment.integerValue(BigNumber.ROUND_FLOOR);



		// Calculate intermediate values for clarity
		const remainingQuantity = contractQuantity.minus(preQuantity).minus(thisAmount);
		const positiveInput = contractQuantity.minus(preQuantity)

		let quantityCriteria = false;
		let quantityMessage = ""



		if (contractQuantity.isPositive() || contractQuantity.isZero()) {
			quantityCriteria = remainingQuantity.isGreaterThanOrEqualTo(0);
			quantityMessage = `입력 가능 수량: ${positiveInput.toNumber()}까지`
		} else {

			if (positiveInput.isPositive()) {
				quantityCriteria = remainingQuantity.isGreaterThanOrEqualTo(0);
				quantityMessage = `입력 가능 수량: ${positiveInput.toNumber()}까지`
			} else {
				quantityCriteria = remainingQuantity.isGreaterThanOrEqualTo(positiveInput);
				quantityMessage = `입력 가능 수량: ${positiveInput.toNumber()}까지`
			}
		}



		const remainingCost = contractAmount.minus(accumlatedAmount).minus(thisAmount);
		const positiveCostInput = contractAmount.minus(accumlatedAmount)

		let costCriteria = false;
		let costMessage = ""


		if (contractAmount.isPositive() || contractAmount.isZero()) {
			costCriteria = remainingCost.isGreaterThanOrEqualTo(0);
			costMessage = `입력 가능 금액: ${positiveCostInput.toNumber()}까지`
		} else {
			if (positiveCostInput.isPositive()) {
				costCriteria = remainingCost.isGreaterThanOrEqualTo(0);
				costMessage = `입력 가능 금액: ${positiveCostInput.toNumber()}까지`
			} else {
				costCriteria = remainingCost.isGreaterThanOrEqualTo(positiveCostInput);
				costMessage = `입력 가능 금액: ${positiveCostInput.toNumber()}까지`
			}
		}






		return (dataItem["계약 유형"] === "총액" ? costCriteria : quantityCriteria);
	};

	// 모든 항목이 유효한 경우에만 true를 반환
	const areAllItemsValid = data.every(calculateValidity);

	// 하나라도 유효하지 않은 항목이 있으면 버튼 활성화
	const disabled = !areAllItemsValid;

	const sum = _.sum(data.map((v) => {
		const type = v["계약 유형"];
		const rate = new BigNumber(v["할증률"]);
		const quantity = new BigNumber(v["계약 수량"]);
		const thisAmount = new BigNumber(v["금회 수량/금액"]);
		const accumlatedAmount = new BigNumber(v["누적 기성지급액"]);
		const contractAmount = new BigNumber(v["계약 금액"]);
		const preQuantity = new BigNumber(v["누적수량"] || 0);  // 값이 없으면 0으로 설정 
		const possbileAmount = new BigNumber(v["possbileAmount"] || 0);

		const materialCost = new BigNumber(v["자재 단가"]);
		const laborCost = new BigNumber(v["노무 단가"]);
		const expenseCost = new BigNumber(v["경비 단가"]);
		const unitPrice = new BigNumber(v["계약 단가"]);
		const materialQty = v["계약 유형"] === "단가" && thisAmount.times(rate.div(100).plus(1)).toFixed(4)
		const qty = v["계약 유형"] === "단가" && thisAmount.toFixed(4)

		let total;
		if (type === "총액") {

			const paymentValue = quantity.times(thisAmount)
			const ispaymentValueNegative = paymentValue.isNegative();
			const abspaymentValue = paymentValue.abs().integerValue(BigNumber.ROUND_FLOOR);

			total = ispaymentValueNegative ? abspaymentValue.times(-1) : abspaymentValue;

		} else {

			const materialValue = materialCost.times(materialQty);
			const isMaterialValueNegative = materialValue.isNegative();
			const absMatValue = materialValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalMatValue = isMaterialValueNegative ? absMatValue.times(-1) : absMatValue;

			const laborValue = laborCost.times(qty);
			const isLaborValueNegative = laborValue.isNegative();
			const absLabValue = laborValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalLabValue = isLaborValueNegative ? absLabValue.times(-1) : absLabValue;

			const expenseValue = expenseCost.times(qty);
			const isExpenseValueNegative = expenseValue.isNegative();
			const absExpValue = expenseValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalExpValue = isExpenseValueNegative ? absExpValue.times(-1) : absExpValue;

			total = finalMatValue.plus(finalLabValue).plus(finalExpValue);

		}

		const finalValue = contractAmount.minus(total).minus(accumlatedAmount);
		const finalValueNegative = finalValue.isNegative();

		const finalQuantity = quantity.minus(thisAmount).minus(preQuantity);
		const finalQuantityNegativeOrZero = finalQuantity.isNegative() || finalQuantity.isZero();;


		if (v["계약 유형"] === "총액" && (finalValue.isZero() || (finalValue.toNumber() > 0 && finalValue.toNumber() < 1))) {
			total = possbileAmount
		}

		if (v["계약 유형"] === "단가" && (finalQuantity.isZero())) {
			total = possbileAmount
		}


		return total.integerValue(BigNumber.ROUND_FLOOR).toNumber(); // 정수값으로 변환하고, JavaScript 숫자로 다시 변환
	}));


	useEffect(() => {
		const calculatePaymentDetails = (data, sum, tabledata) => {
			const thisTimePayment = Math.round((sum / data.amount) * data.AdvPayNetAmount) > 0 ? Math.round((sum / data.amount) * data.AdvPayNetAmount) : 0;
			const inThisTime = _.sumBy(data.data.filter(v => v.NumberingPD.split("차")[0] < tabledata.기성차수), "AdvReturnAmount");
			const remaining = data.AdvPayNetAmount - inThisTime;
			const isLastPayment = (tabledata.originalAmount - sum - _.sumBy(tabledata.sov, "누적 기성지급액")) <= 0 || (sum === _.sumBy(tabledata.sov, "possbileAmount"));

			return {
				remain: remaining,
				thisTime: (remaining < thisTimePayment || sum === tabledata.originalAmount || isLastPayment) ? remaining : thisTimePayment,
				adv: data.AdvPayNetAmount,
				record_no: data.record,
				isLastPayment: isLastPayment
			};
		};

		const fetchData = async () => {
			try {
				const response = await axios.get(`/getadvpayment?record=${tabledata.record}&project=${tabledata.project}&number=${tabledata.기성차수}`);
				if (response.data.success) {
					const paymentDetails = calculatePaymentDetails(response.data, sum, tabledata);
					setAdvpayment(paymentDetails);
				} else {
					setAdvpayment({
						remain: 0,
						thisTime: 0,
						adv: 0,
						record_no: "",
						isLastPayment: false
					});
					if (!showNoAdvancePaymentAlert) {
						Swal.fire('정보', '선급금이 없는 계약입니다', 'info');
						setShowNoAdvancePaymentAlert(true);
					}
				}
			} catch (error) {
				console.error('Error fetching data:', error);
				Swal.fire('Error', 'An error occurred while fetching data', 'error');
			}
		};

		fetchData();
	}, [tabledata, sum]);

	const fileInputRef = useRef(null);

	const downloadExcel = () => {
		const dataForExcel = exportData.map(item => {
			const {
				bItemID,
				inEdit,
				Description,
				LineAutoSeq,
				"금회 금액": 금회금액,
				possbileAmount,  // 이 항목을 추가로 제거
				...rest
			} = item;

			return {
				...rest,
				"기성 신청 가능 금액": possbileAmount  // possbileAmount를 "기성 신청 가능 금액"으로 매핑
			};
		});

		const worksheet = utils.json_to_sheet(dataForExcel);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, "Sheet1");
		writeFile(workbook, '기성신청데이터.xlsx');
	};




	const pageChange = event => {
		const targetEvent = event.targetEvent;
		const take = targetEvent.value === 'All' ? data.length : event.page.take;
		if (targetEvent.value) {
			setPageSizeValue(targetEvent.value);
		}
		setPage({
			...event.page,
			take
		});
	};

	const [changes, setChanges] = React.useState(false);
	const enterEdit = (dataItem, field) => {
		const newData = data.map(item => ({
			...item,
			[EDIT_FIELD]: item.id === dataItem.id ? field : undefined
		}));
		setData(newData);
	};
	const exitEdit = () => {
		const newData = data.map(item => ({
			...item,
			[EDIT_FIELD]: undefined
		}));
		setData(newData);
	};


	const itemChange = event => {
		let field = event.field || '';
		let newData = data.map(item => {
			if (item.id === event.dataItem.id) {
				const rate = new BigNumber(item["할증률"]);
				const quantity = new BigNumber(item["계약 수량"]);
				const accumlatedAmount = new BigNumber(item["누적 기성지급액"]);
				const contractAmount = new BigNumber(item["계약 금액"]);
				const thisAmount = new BigNumber(event.value || 0);  // 값이 없으면 0으로 설정 
				const preQuantity = new BigNumber(item["누적수량"] || 0);  // 값이 없으면 0으로 설정 
				const possbileAmount = new BigNumber(item["possbileAmount"] || 0);

				const materialCost = new BigNumber(item["자재 단가"]);

				const rate2 = rate.plus(100).div(100)
				const materialCost2 = materialCost.times(rate2);


				const laborCost = new BigNumber(item["노무 단가"]);
				const expenseCost = new BigNumber(item["경비 단가"]);

				const divValue = materialCost2.plus(laborCost).plus(expenseCost)


				const materialQty = item["계약 유형"] === "단가" && thisAmount.times(rate.div(100).plus(1)).toFixed(4)
				const qty = item["계약 유형"] === "단가" && thisAmount.toFixed(4)


				let calculatedAmount;
				if (item["계약 유형"] === "총액") {

					const paymentValue = quantity.times(thisAmount)
					const ispaymentValueNegative = paymentValue.isNegative();
					const abspaymentValue = paymentValue.abs().integerValue(BigNumber.ROUND_FLOOR);

					calculatedAmount = ispaymentValueNegative ? abspaymentValue.times(-1) : abspaymentValue;

				} else {
					const materialValue = materialCost.times(materialQty);
					const isMaterialValueNegative = materialValue.isNegative();
					const absMatValue = materialValue.abs().integerValue(BigNumber.ROUND_FLOOR);
					const finalMatValue = isMaterialValueNegative ? absMatValue.times(-1) : absMatValue;

					const laborValue = laborCost.times(qty);
					const isLaborValueNegative = laborValue.isNegative();
					const absLabValue = laborValue.abs().integerValue(BigNumber.ROUND_FLOOR);
					const finalLabValue = isLaborValueNegative ? absLabValue.times(-1) : absLabValue;

					const expenseValue = expenseCost.times(qty);
					const isExpenseValueNegative = expenseValue.isNegative();
					const absExpValue = expenseValue.abs().integerValue(BigNumber.ROUND_FLOOR);
					const finalExpValue = isExpenseValueNegative ? absExpValue.times(-1) : absExpValue;

					calculatedAmount = finalMatValue.plus(finalLabValue).plus(finalExpValue);


				}


				const finalValue = contractAmount.minus(calculatedAmount).minus(accumlatedAmount);
				const finalValueNegative = finalValue.isNegative();

				const finalQuantity = quantity.minus(thisAmount).minus(preQuantity);
				const finalLastQty = quantity.minus(preQuantity);
				const finalQuantityNegativeOrZero = finalQuantity.isNegative() || finalQuantity.isZero();

				if (item["계약 유형"] === "총액" && (finalValue.isZero() || (finalValue.toNumber() > 0 && finalValue.toNumber() < 1))) {
					calculatedAmount = contractAmount.minus(accumlatedAmount)

					return {
						...item,
						[field]: event.value,
						"금회 금액": possbileAmount.toNumber(),
						"금회 수량/금액": possbileAmount.toNumber()
					};
				}

				if (item["계약 유형"] === "단가" && (finalQuantity.isZero())) {
					calculatedAmount = contractAmount.minus(accumlatedAmount)

					return {
						...item,
						[field]: event.value,
						"금회 금액": possbileAmount.toNumber(),
						"금회 수량/금액": finalLastQty.toNumber()
					};
				}

				return {
					...item,
					[field]: event.value,
					"금회 금액": calculatedAmount.isNaN() ? 0 : calculatedAmount.toNumber(),
				};
			}
			return item;
		});

		setData(newData);
		setChanges(true);
	};



	const customCellRender = (td, props) => <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;
	const customRowRender = (tr, props) => <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />;

	const ItemCell = (props) => {
		const dataItem = props.dataItem;
		const contractType = dataItem["계약 유형"];
		const surchargeRate = new BigNumber(dataItem["할증률"] || 0);
		const contractQuantity = new BigNumber(dataItem["계약 수량"]);
		const materialCost = new BigNumber(dataItem["자재 단가"] || 0);
		const laborCost = new BigNumber(dataItem["노무 단가"] || 0);
		const expenseCost = new BigNumber(dataItem["경비 단가"] || 0);
		const thisAmount = new BigNumber(dataItem["금회 수량/금액"] || 0);
		const contractUnitPrice = new BigNumber(dataItem["계약 단가"] || 0);
		const preQuantity = new BigNumber(dataItem["누적수량"] || 0);  // 값이 없으면 0으로 설정 
		const possbileAmount = new BigNumber(dataItem["possbileAmount"] || 0);

		const accumlatedAmount = new BigNumber(dataItem["누적 기성지급액"]);
		const contractAmount = new BigNumber(dataItem["계약 금액"]);

		const materialQty = dataItem["계약 유형"] === "단가" && thisAmount.times(surchargeRate.div(100).plus(1)).toFixed(4)
		const qty = dataItem["계약 유형"] === "단가" && thisAmount.toFixed(4)

		let value;
		if (contractType === "총액") {

			const paymentValue = contractQuantity.times(thisAmount)
			const ispaymentValueNegative = paymentValue.isNegative();
			const abspaymentValue = paymentValue.abs().integerValue(BigNumber.ROUND_FLOOR);

			value = ispaymentValueNegative ? abspaymentValue.times(-1) : abspaymentValue;

		} else {

			const materialValue = materialCost.times(materialQty);
			const isMaterialValueNegative = materialValue.isNegative();
			const absMatValue = materialValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalMatValue = isMaterialValueNegative ? absMatValue.times(-1) : absMatValue;

			const laborValue = laborCost.times(qty);
			const isLaborValueNegative = laborValue.isNegative();
			const absLabValue = laborValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalLabValue = isLaborValueNegative ? absLabValue.times(-1) : absLabValue;

			const expenseValue = expenseCost.times(qty);
			const isExpenseValueNegative = expenseValue.isNegative();
			const absExpValue = expenseValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalExpValue = isExpenseValueNegative ? absExpValue.times(-1) : absExpValue;

			value = finalMatValue.plus(finalLabValue).plus(finalExpValue);


		}


		const finalValue = contractAmount.minus(value).minus(accumlatedAmount);
		const finalValueNegative = finalValue.isNegative();


		const finalQuantity = contractQuantity.minus(thisAmount).minus(preQuantity);
		const finalQuantityNegativeOrZero = finalQuantity.isNegative() || finalQuantity.isZero();;

		if (dataItem["계약 유형"] === "총액" && (finalValue.isZero() || (finalValue.toNumber() > 0 && finalValue.toNumber() < 1))) {
			value = possbileAmount
		}

		if (dataItem["계약 유형"] === "단가" && finalQuantity.isZero()) {
			value = possbileAmount
		}




		const displayValue = value.isNaN() ? "0" : value.toFormat();

		return (
			<td colSpan={props.colSpan} role={"gridcell"} className='k-table-td'>
				{displayValue}
			</td>
		);
	};


	// 검증 메시지를 추출하는 함수
	const getValidationMessage = (dataItem) => {
		const contractType = dataItem["계약 유형"];
		const surchargeRate = new BigNumber(dataItem["할증률"]);
		const contractQuantity = new BigNumber(dataItem["계약 수량"]);
		const possbileAmount = new BigNumber(dataItem.possbileAmount);
		const materialCost = new BigNumber(dataItem["자재 단가"]);
		const laborCost = new BigNumber(dataItem["노무 단가"]);
		const expenseCost = new BigNumber(dataItem["경비 단가"]);
		const thisAmount = new BigNumber(dataItem["금회 수량/금액"]);
		const contractUnitPrice = new BigNumber(dataItem["계약 단가"]);
		const preQuantity = new BigNumber(dataItem["누적수량"] || 0);  // 값이 없으면 0으로 설정 

		const accumlatedAmount = new BigNumber(dataItem["누적 기성지급액"]);
		const contractAmount = new BigNumber(dataItem["계약 금액"]);

		const materialQty = dataItem["계약 유형"] === "단가" && thisAmount.times(surchargeRate.div(100).plus(1)).toFixed(4)
		const qty = dataItem["계약 유형"] === "단가" && thisAmount.toFixed(4)

		let payment;
		if (contractType === "총액") {

			const paymentValue = contractQuantity.times(thisAmount)
			const ispaymentValueNegative = paymentValue.isNegative();
			const abspaymentValue = paymentValue.abs().integerValue(BigNumber.ROUND_FLOOR);

			payment = ispaymentValueNegative ? abspaymentValue.times(-1) : abspaymentValue;

		} else {
			const materialValue = materialCost.times(materialQty);
			const isMaterialValueNegative = materialValue.isNegative();
			const absMatValue = materialValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalMatValue = isMaterialValueNegative ? absMatValue.times(-1) : absMatValue;

			const laborValue = laborCost.times(qty);
			const isLaborValueNegative = laborValue.isNegative();
			const absLabValue = laborValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalLabValue = isLaborValueNegative ? absLabValue.times(-1) : absLabValue;

			const expenseValue = expenseCost.times(qty);
			const isExpenseValueNegative = expenseValue.isNegative();
			const absExpValue = expenseValue.abs().integerValue(BigNumber.ROUND_FLOOR);
			const finalExpValue = isExpenseValueNegative ? absExpValue.times(-1) : absExpValue;

			payment = finalMatValue.plus(finalLabValue).plus(finalExpValue);
		}


		const finalValue = contractAmount.minus(payment).minus(accumlatedAmount);

		const finalQuantity = contractQuantity.minus(thisAmount).minus(preQuantity);


		// Calculate intermediate values for clarity
		const remainingQuantity = contractQuantity.minus(preQuantity).minus(thisAmount);
		const positiveInput = contractQuantity.minus(preQuantity)

		let quantityCriteria = false;
		let quantityMessage = ""



		if (contractQuantity.isPositive() || contractQuantity.isZero()) {
			quantityCriteria = remainingQuantity.isGreaterThanOrEqualTo(0);
			quantityMessage = `입력 가능 수량: ${positiveInput.toNumber()}까지`
		} else {

			if (positiveInput.isPositive()) {
				quantityCriteria = remainingQuantity.isGreaterThanOrEqualTo(0);
				quantityMessage = `입력 가능 수량: ${positiveInput.toNumber()}까지`
			} else {
				quantityCriteria = remainingQuantity.isGreaterThanOrEqualTo(positiveInput);
				quantityMessage = `입력 가능 수량: ${positiveInput.toNumber()}까지 또는 0`
			}
		}



		const remainingCost = contractAmount.minus(accumlatedAmount).minus(thisAmount);
		const positiveCostInput = contractAmount.minus(accumlatedAmount)

		let costCriteria = false;
		let costMessage = ""


		if (contractAmount.isPositive() || contractAmount.isZero()) {
			costCriteria = remainingCost.isGreaterThanOrEqualTo(0);
			costMessage = `입력 가능 금액: ${positiveCostInput.toNumber()}까지`
		} else {
			if (positiveCostInput.isPositive()) {
				costCriteria = remainingCost.isGreaterThanOrEqualTo(0);
				costMessage = `입력 가능 금액: ${positiveCostInput.toNumber()}까지`
			} else {
				costCriteria = remainingCost.isGreaterThanOrEqualTo(positiveCostInput);
				costMessage = `입력 가능 금액: ${positiveCostInput.toNumber()}까지 또는 0`
			}
		}


		if (contractType === "총액") {
			return costCriteria ? "ok" : `error: ${costMessage}`;
		} else {
			return quantityCriteria ? "ok" : `error: ${quantityMessage}`;
		}
	};


	const ItemCell2 = (props) => {
		const message = getValidationMessage(props.dataItem);
		const isError = message.startsWith("error");
		return (
			<td colSpan={props.colSpan} role={"gridcell"} className='k-table-td'>
				{isError ?
					<span className="badge badge-light-danger fs-base fs-9">{message}</span> :
					<span className="badge badge-light-success fs-base fs-10">ok</span>
				}
			</td>
		);
	};


	const MCell = (props) => {
		const field = props.field || "";
		const value = props.dataItem[field];
		const title = props.title || ""; // 칼럼의 제목을 가져옵니다.

		const formatNumber = (num) => {
			// "수량"이 포함된 칼럼이거나 "누적수량" 필드인 경우 소수점 4자리까지 표시
			if (title.includes("수량") || field === "누적수량") {
				return num.toLocaleString(undefined, { minimumFractionDigits: 4, maximumFractionDigits: 4 });
			}
			// 그 외의 경우 기본 형식으로 표시
			return num.toLocaleString();
		};

		return (
			<td
				colSpan={props.colSpan}
				role={"gridcell"}
				className='k-table-td'
			>
				{typeof value === 'number'
					? formatNumber(value)
					: value?.toLocaleString()}
			</td>
		);
	};
	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};


	const [files, setFiles] = React.useState([]);
	const [fileData, setFileData] = React.useState([]);
	const [uploadfiles, setUploadFiles] = React.useState([]);


	const onAdd = useCallback((event) => {
		if (!event.target.files) {
			return;
		}
		const file = event.target.files[0];

		setFiles(file['name']);

		const reader = new FileReader();
		reader.onloadend = (ev) => {
			if (!ev?.target?.result) {
				return;
			}

			const wb = read(ev.target.result, { type: 'binary', cellText: false, cellDates: true });
			const sheets = wb.SheetNames;

			const header = utils.sheet_to_json(wb.Sheets[sheets[0]], { header: 1 })[0];


			if (sheets.length) {
				const expectedHeaders = [
					"id",
					"계약 유형",
					"품명",
					"규격",
					"단위",
					"계약 수량",
					"계약 단가",
					"자재 단가",
					"노무 단가",
					"경비 단가",
					"계약 금액",
					"할증률",
					"누적수량",
					"누적 기성지급액",
					"금회 수량/금액",
					"기성 신청 가능 금액"
				];

				if (header.length === expectedHeaders.length &&
					header.every((value, index) => value === expectedHeaders[index])) {

					const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);

					const add_row = _.differenceBy(rows, data, 'id');
					const exist = _.differenceBy(rows, add_row, 'id');

					setData(exportData.map(item => {
						const existItem = exist.find(e => e.id === item.id);

						if (existItem) {
							const rate = new BigNumber(existItem["할증률"]);

							const quantity = new BigNumber(existItem["계약 수량"]);
							const preQuantity = new BigNumber(existItem["누적수량"] || 0);  // 값이 없으면 0으로 설정 
							const possbileAmount = new BigNumber(existItem["기성 신청 가능 금액"]);


							const materialCost = new BigNumber(existItem["자재 단가"]);
							const laborCost = new BigNumber(existItem["노무 단가"]);
							const expenseCost = new BigNumber(existItem["경비 단가"]);
							const thisAmount = new BigNumber(existItem["금회 수량/금액"]);

							const accumlatedAmount = new BigNumber(existItem["누적 기성지급액"]);
							const contractAmount = new BigNumber(existItem["계약 금액"]);

							const rate2 = rate.plus(100).div(100)
							const materialCost2 = materialCost.times(rate2);
							const divValue = materialCost2.plus(laborCost).plus(expenseCost)


							const materialQty = existItem["계약 유형"] === "단가" && thisAmount.times(rate.div(100).plus(1)).toFixed(4)
							const qty = existItem["계약 유형"] === "단가" && thisAmount.toFixed(4)
							const unitPrice = new BigNumber(existItem["계약 단가"]);

							let calculatedAmount;
							if (existItem["계약 유형"] === "총액") {

								const paymentValue = quantity.times(thisAmount)
								const ispaymentValueNegative = paymentValue.isNegative();
								const abspaymentValue = paymentValue.abs().integerValue(BigNumber.ROUND_FLOOR);


								calculatedAmount = ispaymentValueNegative ? abspaymentValue.times(-1) : abspaymentValue;


							} else {
								const materialValue = materialCost.times(materialQty);
								const isMaterialValueNegative = materialValue.isNegative();
								const absMatValue = materialValue.abs().integerValue(BigNumber.ROUND_FLOOR);
								const finalMatValue = isMaterialValueNegative ? absMatValue.times(-1) : absMatValue;

								const laborValue = laborCost.times(qty);
								const isLaborValueNegative = laborValue.isNegative();
								const absLabValue = laborValue.abs().integerValue(BigNumber.ROUND_FLOOR);
								const finalLabValue = isLaborValueNegative ? absLabValue.times(-1) : absLabValue;

								const expenseValue = expenseCost.times(qty);
								const isExpenseValueNegative = expenseValue.isNegative();
								const absExpValue = expenseValue.abs().integerValue(BigNumber.ROUND_FLOOR);
								const finalExpValue = isExpenseValueNegative ? absExpValue.times(-1) : absExpValue;

								calculatedAmount = finalMatValue.plus(finalLabValue).plus(finalExpValue);

							}

							const finalValue = contractAmount.minus(calculatedAmount).minus(accumlatedAmount);
							const finalValueNegative = finalValue.isNegative();

							const finalQuantity = quantity.minus(thisAmount).minus(preQuantity);

							const finalQuantityNegativeOrZero = finalQuantity.isNegative() ||
								finalQuantity.isZero() ||
								finalQuantity.isLessThan(new BigNumber('0.0001'));

							const finalLastQty = quantity.minus(preQuantity);


							if (existItem["계약 유형"] === "총액" && (finalValue.isZero() || (finalValue.toNumber() > 0 && finalValue.toNumber() < 1))) {

								return {
									...item,
									"금회 수량/금액": possbileAmount.toNumber(),
									"금회 금액": possbileAmount.toNumber(),
								};
							}

							if (existItem["계약 유형"] === "단가" && (finalQuantity.isZero() || (finalQuantity.abs().isLessThan(new BigNumber('0.0001'))))) {

								return {
									...item,
									"금회 수량/금액": finalLastQty.toNumber(),
									"금회 금액": possbileAmount.toNumber(),
								};
							}

							return {
								...item,
								"금회 수량/금액": existItem["계약 유형"] === "총액" ? calculatedAmount.toNumber() : thisAmount.toFixed(4),
								"금회 금액": calculatedAmount.toNumber()
							};
						}
						return item;
					}));



					Swal.fire({
						icon: 'success',
						title: '엑셀을 테이블로 로드했습니다. 데이터에 문제가 없으면 기성 신청 버튼을 클릭하세요',
						timer: 2000
					});

				} else {
					Swal.fire({
						icon: 'error',
						title: '임포트한 엑셀 헤더를 확인하세요. 다운로드에 있는 헤더를 사용해야합니다.',
						timer: 2000
					});
				}
			}
		};
		reader.readAsArrayBuffer(file);
	}, [exportData]);

	const onAddHandler = (e) => {
		setUploadFiles(e.newState);

		const files = e.newState; // Process all files, not just affectedFiles
		const updatedState = [];

		let filesProcessed = 0; // Track the number of files processed

		if (files.length === 0) {
			setFileData([]); // Clear fileData if no files are left
			return;
		}

		files.forEach((file) => {
			const cc = file.getRawFile();
			const reader = new FileReader();

			reader.onload = function (loadEvent) {
				const base64 = loadEvent.target.result;
				const updatedFile = {
					name: file.name,
					base64: base64,
				};

				updatedState.push(updatedFile);
				filesProcessed++;

				// Check if all files have been processed
				if (filesProcessed === files.length) {
					setFileData(updatedState); // Update fileData with all files
				}
			};

			reader.readAsDataURL(cc);
		});
	};


	const onRemoveHandler = (e) => {
		setUploadFiles(e.newState);

		const files = e.newState;
		const updatedState = [];

		let filesProcessed = 0;

		if (files.length === 0) {
			setFileData([]); // Clear fileData if no files are left
			return;
		}

		files.forEach((file) => {
			const cc = file.getRawFile();
			const reader = new FileReader();

			reader.onload = function (loadEvent) {
				const base64 = loadEvent.target.result;
				const updatedFile = {
					name: file.name,
					base64: base64,
				};

				updatedState.push(updatedFile);
				filesProcessed++;

				if (filesProcessed === files.length) {
					setFileData(updatedState);
				}
			};

			reader.readAsDataURL(cc);
		});
	};


	const PriceCell = (props) => {
		let formattedSum;
		if (sum < 0) {
			formattedSum = Math.floor(Math.abs(sum)).toLocaleString();
			formattedSum = `-${formattedSum}`;
		} else {
			formattedSum = Math.floor(sum).toLocaleString();
		}

		return (
			<td colSpan={props.colSpan} style={props.style}>
				{formattedSum}
			</td>
		);
	};


	const handlerequest = React.useCallback(() => {


		// 파일 데이터가 빈 배열인지 확인
		if (fileData.length === 0) {
			Swal.fire({
				title: '오류',
				text: '파일을 첨부해주세요',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}

		// 파일 데이터가 빈 배열인지 확인
		if (sum === 0) {
			Swal.fire({
				title: '오류',
				text: '금회 금액이 없습니다.',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}

		if (tabledata.possiblePayment - sum < Advpayment.remain) {
			Swal.fire({
				title: '오류',
				text: '선급금 반제 금액이 기성잔여금 보다 큽니다. 담당자에게 연락하여 선급금 반제 계획 수정 요청을 하시기 바랍니다.',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}
		// 파일 데이터가 빈 배열인지 확인
		if (Advpayment && !Advpayment.isLastPayment && Advpayment.thisTime && Advpayment.thisTime >= sum) {
			Swal.fire({
				title: '오류',
				text: '금회 신청 금액이 선급 반제 금액보다 적습니다. 선급 반제 금액을 줄이시기 바랍니다.',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}

		// 잔여보다 큰지 확인
		if (sum > tabledata.possiblePayment) {
			Swal.fire({
				title: '오류',
				text: '금회 신청 금액이 남은 기성 잔여금보다 큽니다.',
				icon: 'error',
				confirmButtonText: '확인'
			});
			return; // 함수 실행을 중지합니다.
		}
		Swal.fire({
			title: '정말 기성 신청을 보내겠습니까?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: '네',
			cancelButtonText: '아니오'
		}).then((result) => {
			if (result.isConfirmed) {

				setIsSubmitting(true);

				const toast = Swal.mixin({
					toast: true,
					position: 'top-end',
					showConfirmButton: false,
					timerProgressBar: true,
					didOpen: (toast) => {
						toast.addEventListener('mouseenter', Swal.stopTimer)
						toast.addEventListener('mouseleave', Swal.resumeTimer)
					}
				});

				toast.fire({
					icon: 'info',
					title: '기성 신청 처리 중...'
				});

				const apiUrl = window.location.pathname.includes('createNewPA') ? '/submitNPA' : '/submitNPA2';


				axios.post(apiUrl, {
					paymentRecordNo: paymentRecordNo,
					lineData: data,
					files: fileData,
					recordData: { project: tabledata.project, title: tabledata.title.slice(0, 50), record: tabledata.record, advRecord: Advpayment.record_no, advAmount: Advpayment.thisTime, PrevAdvPayRemain: Advpayment.remain }
				})
					.then((response) => {
						toast.close(); // 처리 완료 후 토스트 메시지 닫기
						setIsSubmitting(false);
						if (response.data.success) {
							Swal.fire({
								title: '성공적으로 제출되었습니다',
								icon: 'success',
								confirmButtonText: '확인'
							}).then(() => {
								navigate('/log-payment'); // 변경된 부분
							});
						} else if (!response.data.success) {
							Swal.fire({
								title: '오류',
								icon: 'error',
								text: response.data.data,
								confirmButtonText: '확인'
							}).then(() => {
								navigate('/log-payment'); // 변경된 부분
							});
						}
						else {
							Swal.fire({
								title: '응답 시간 초과',
								icon: 'info',
								text: "내역과 첨부 파일이 네트워크를 초과하였습니다. 기성 페이지에서 신청되었는지 확인바랍니다.",
								confirmButtonText: '확인'
							}).then(() => {
								navigate('/log-payment'); // 변경된 부분
							});
						}
					})
					.catch(() => {
						toast.close(); // 처리 완료 후 토스트 메시지 닫기
						setIsSubmitting(false);
						Swal.fire({
							title: '응답 시간 초과',
							icon: 'info',
							text: "내역과 첨부 파일이 네트워크를 초과하였습니다. 기성 페이지에서 신청되었는지 확인바랍니다.",
							confirmButtonText: '확인'
						}).then(() => {
							navigate('/log-payment'); // 변경된 부분
						});
					});
			}
		});
	}, [data, fileData, Advpayment]);



	const CustomNumericCell = props => {
		const { dataItem, field, onChange } = props;
		const value = dataItem[field];
		const inEdit = dataItem[EDIT_FIELD] === field;
		const isUnitPrice = dataItem["계약 유형"] === "단가";

		const [localValue, setLocalValue] = useState(value);

		useEffect(() => {
			setLocalValue(value);
		}, [value]);

		const debouncedOnChange = useCallback(
			debounce((newValue) => {
				onChange({
					dataItem: dataItem,
					field: field,
					syntheticEvent: { target: { value: newValue } },
					value: newValue
				});
			}, 900),
			[dataItem, field, onChange]
		);

		const handleChange = (event) => {
			const newValue = event.value;
			setLocalValue(newValue);
			debouncedOnChange(newValue);
		};

		const cellStyle = {
			fontSize: '9px !important',
			padding: '0px',
			height: '100%',
		};

		if (inEdit) {
			return (
				<td style={cellStyle}>
					<NumericTextBox
						value={localValue}
						format={isUnitPrice ? "n4" : "n0"}
						onChange={handleChange}
						width="100%"
						height="100%"
					/>
				</td>
			);
		}

		return (
			<td
				onClick={() => props.enterEdit(dataItem, field)}
				style={cellStyle}
			>
				{localValue !== undefined && localValue !== null
					? Number(localValue).toFixed(isUnitPrice ? 4 : 0)
					: (isUnitPrice ? '0.0000' : '0')}
			</td>
		);
	};

	const [filterErrors, setFilterErrors] = useState(false);

	const toggleFilterErrors = () => {
		setFilterErrors(!filterErrors);
		setPage(initialDataState);
	};

	const filteredData = filterErrors
		? data.filter(item => !calculateValidity(item))
		: data;



	// 현재값 다운로드 시 검증 결과 컬럼 추가
	const downloadExcelPresent = () => {
		const dataForExcel = data.map(item => {
			const {
				bItemID,
				inEdit,
				Description,
				LineAutoSeq,
				"금회 금액": 금회금액,
				possbileAmount,
				...rest
			} = item;

			const validationMessage = getValidationMessage(item);

			return {
				...rest,
				"금회 금액": 금회금액,
				"기성 신청 가능 금액": possbileAmount,
				"검증 결과": validationMessage
			};
		});

		const worksheet = utils.json_to_sheet(dataForExcel);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, "Sheet1");
		writeFile(workbook, '기성신청데이터_현재값.xlsx');
	};



	return <><div><label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
		<span className='fw-semibold text-gray-400 '>선급금:{Advpayment && Advpayment.adv && Advpayment.adv.toLocaleString()}원</span> &nbsp; &nbsp;
		<span className='fw-semibold text-gray-400'> 선급금 잔여:{Advpayment && Advpayment.remain && Advpayment.remain.toLocaleString()}원</span> &nbsp; &nbsp;
		<span> {Advpayment && Advpayment.remain && ((Advpayment.remain / Advpayment.adv) * 100).toFixed(1)}%</span>&nbsp; &nbsp;
		<span> {tabledata.기성차수}차 선급금 반제금액: {Advpayment && Advpayment.thisTime && Advpayment.thisTime.toLocaleString()}원</span>&nbsp; &nbsp;

	</label>
	</div>
		<LocalizationProvider language="ko-KR">
			<IntlProvider locale="ko-KR">
				<Grid style={{
					height: '550px'
				}} data={filteredData.slice(page.skip, page.take + page.skip)} onPageChange={pageChange} className='payment'
					skip={page.skip} take={page.take} total={filteredData.length}
					resizable={true}
					// cells={{
					// 	edit:{numeric:MyNumericCustomCell}
					// }}
					pageable={{
						buttonCount: 4,
						pageSizes: [5, 10, 15, 'All'],
						pageSizeValue: pageSizeValue
					}} dataItemKey={'id'} rowHeight={28} onItemChange={itemChange} cellRender={customCellRender} rowRender={customRowRender} editField={EDIT_FIELD}>
					<GridToolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', gap: '10px' }}>

							<button
								className={`btn btn-sm fw-bold btn-${filterErrors ? 'primary' : 'secondary'}`}
								type='button'
								onClick={toggleFilterErrors}
							>
								<i className={`fs-2 bi ${filterErrors ? 'bi-funnel-fill' : 'bi-funnel'}`}></i>
								{filterErrors ? '전체 보기' : '검증 에러 내역 보기'}
							</button>

							<button className='btn btn-sm fw-bold btn-primary' type='button' onClick={downloadExcel}>
								<i className="ki-duotone ki-file-down fs-2">
									<span className="path1"></span>
									<span className="path2"></span>
								</i>양식 다운로드
							</button>
							<button className='btn btn-sm fw-bold btn-secondary' type='button' onClick={handleButtonClick}>
								<i className="ki-duotone ki-file-up fs-2">
									<span className="path1"></span>
									<span className="path2"></span>
								</i>임포트
								<input type="file" accept=".csv, .xls, .xlsx" hidden ref={fileInputRef} onChange={onAdd} />
							</button>
						</div>
						<span>{files}</span>
						<div style={{ display: 'flex', gap: '10px', position: "absolute", right: 8 }}>

							<button className='btn btn-sm fw-bold btn-secondary' type='button' onClick={downloadExcelPresent}>
								<i className="ki-duotone ki-file-down fs-2">
									<span className="path1"></span>
									<span className="path2"></span>
								</i>현재값 다운로드
							</button>

							<button
								className='btn btn-sm btn-light btn-color-muted btn-active-light-danger'
								type='button'
								disabled={disabled || isSubmitting}
								onClick={handlerequest}
							>
								{isSubmitting ? (
									<>
										<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
										처리 중...
									</>
								) : (
									<>
										<i className="ki-duotone ki-exit-right fs-2">
											<span className="path1"></span>
											<span className="path2"></span>
										</i>
										기성 신청
									</>
								)}
							</button>
						</div>

					</GridToolbar>
					<Column field="id" title="Id" width="50px" editable={false} />
					<Column field="계약 유형" title="유형" width="50px" editable={false} />
					<Column field="품명" title="품명" width="140px" editable={false} />
					<Column field="규격" title="규격" width="200px" editable={false} />
					<Column field="단위" title="단위" width="50px" editable={false} />
					<Column field="계약 수량" title="계약 수량" width="90px" editable={false} cell={MCell} />
					<Column field="계약 단가" title="계약 단가" width="90px" editable={false} cell={MCell} />
					<Column field="자재 단가" title="자재 단가" width="90px" editable={false} cell={MCell} />
					<Column field="노무 단가" title="노무 단가" width="90px" editable={false} cell={MCell} />
					<Column field="경비 단가" title="경비 단가" width="90px" editable={false} cell={MCell} />
					<Column field="계약 금액" title="계약 금액" width="110px" editable={false} cell={MCell} />
					<Column field="할증률" title="할증률(%)" width="90px" editable={false} />
					<Column field="누적 기성지급액" title="누적 기성지급액" width="150px" editable={false} cell={MCell} />
					<Column field="누적수량" title="누적 기성수량" width="90px" editable={false} cell={MCell} />
					{/* <Column field="possbileAmount" title="기성가능금액" width="150px" editable={false} show="false"/> */}
					<Column
						field="금회 수량/금액"
						title="금회 수량/금액"
						width="100px"
						cell={props => <CustomNumericCell {...props} enterEdit={enterEdit} />}
					/>
					<Column field="금회 금액" title="금회 금액" width="100px" editable={false} cell={ItemCell} footerCell={PriceCell} />
					<Column title="검증" width="100px" editable={false} cell={ItemCell2} />
				</Grid>
				<Upload multiple={true} onAdd={onAddHandler} files={uploadfiles}
					onRemove={onRemoveHandler} autoUpload={false} batch={false} showActionButtons={false}></Upload>
			</IntlProvider>
		</LocalizationProvider>
	</>;
}

export default PaymentTable;